@import "styles/variables";
@import "styles/mixings";

.box {

  div:first-child {
    height: 121px;
    background: url("../../../../public/assets/resort/blue-mountains.svg") 50% 105% repeat-x;
  }

  div:last-child {
    height: 0;
    background: $primary;
  }

  @include media-breakpoint-up(sm) {
    margin-top: -100px;
  }
}