@import "styles/variables";
@import "styles/mixings";

.player {
  .watch {
    display: none;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    font-weight: bold;
    font-size: 12 * $px-base;
    line-height: 140%;
    color: $white;
    padding: 10px 16px;
    border: none;

    @include media-breakpoint-down(md) {
      display: block;
    }

    &.isOpenMobile {
      display: none;
    }

    svg {
      width: 14px;
      height: 14px;
      margin-bottom: 5px;
    }
  }

  .playerBox {
    @include media-breakpoint-down(md) {
      display: none;
    }

    &.isOpenMobile {
      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    .iframe {
      width: 216px;
      height: 122px;
    }
  }
}